import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import './styles.scss';
import Form from './Form';
import CarSummary from './CarSummary';

@inject('CbvStore')
@observer
class Slutfor extends Component {

    componentDidMount() {
        if(!this.props.CbvStore.fetchedApiData) {
            this.props.CbvStore.navigate('/valj-modell');
        } else {
            // Tracking - Pixels
            window.Optanon.InsertHtml('<img src="https://ad.doubleclick.net/ddm/activity/src=8562653;type=volvo001;cat=se-lo003;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/> <img src="https://secure.adnxs.com/px?id=1034506&seg=14681577&t=2" width="1" height="1" /> <img src="//se-gmtdmp.mookie1.com/t/v2/activity?tagid=V2_669684&src.rand=[timestamp]&" style="display:none;"/> <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1789914651042181&ev=PageView&noscript=1" /> <img src="https://secure.adnxs.com/px?id=1055686&t=2" width="1" height="1" /> <img src="https://secure.adnxs.com/px?id=1078605&t=2" width="1" height="1" />', 'fbiPixels', null, null, 4);
        }
    }

    render() {

        if(!this.props.CbvStore.fetchedApiData) {
            return <div></div>
        }

        return (
            <div className="Step3Wrapper">
                <CarSummary></CarSummary>
                <Form></Form>
            </div>
        );
    }
}

export default Slutfor;
