import React, {Component} from 'react';

import './App.scss';
import CbvStart from './CbvStart';

class App extends Component {
    render() {
        return (
            <div className="cbvStartWrapper">
                <CbvStart></CbvStart>
            </div>
        );
    }
}

export default App;
