import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import './styles.scss';
import Retailers from './Retailers';
//import { Spinner } from 'vcc-ui';
import SPAR from '../../../../utils/spar';
import axios from 'axios';

@inject('CbvStore')
@observer
class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            busy: false,
            ssnError: false,
            gdprText: ''
        }
    }

    validateEmail = (email) => {
       var re = /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       return re.test(email);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.props.CbvStore[name] = value;
    }

    ssnEnterHandler = (e) => {
        if(e.charCode === 13){
          this.personalNumberLookup();
        }

        // Tracking
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                'event':'EventTracking',
                'category':'Leads Private',
                'action':'Care by Volvo',
                'label':'Hämta personuppgifter'
            });
        }
    }

    personalNumberLookup = (e) => {
        //e.preventDefault();
        let ssn = this.props.CbvStore.customerSSN;
        console.log("SSN Lookup for", ssn);

        // This updates the UI to show the user an ajax call is being made.
        // Important that you use this variable to render some kind of loading graphic.
        this.setState({
            "busy": true,
        });

        SPAR.lookupSSN(ssn).then(result => {
            console.log('Result', result);

            if (result.found) {
                // Populate form fields
                this.props.CbvStore.formName = result.result.fornamn + " " + result.result.efternamn;
                this.props.CbvStore.formAdress = result.result.utdelningsadress2;
                this.props.CbvStore.formZip = result.result.postnr;
                this.props.CbvStore.formPosition = result.result.postort;
                // TODO Focus on dealer select or scroll to the fields so the user can see they were populated
                this.setState({
                    "ssnError": false
                });
            } else {
                // TODO Handle case when not found or invalid SSN
                this.setState({
                    "ssnError": true,
                });
            }
        }).catch(e => {
            console.log('Error', e);
            // TODO Handle case when there was a problem with the backend
            this.setState({
                "ssnError": true,
            });
        }).then(() => {
            // Resets the UI loading graphic
            this.setState({
                "busy": false
            });
        })

    }

    componentDidMount() {
        this.props.CbvStore.formError = false;

        axios.get('https://dms.fbinhouse.se/api/266/?campaign=compliance&tag=consent,nodealer')
            .then(data => {
                this.setState({
                    gdprText: data.data[0].values.compliancetext.paragraphs[0].text
                })
            })
    }

    render() {
        return (
            <div className="step3FormWrapper">
                <h2>Dina uppgifter</h2>
                <form>

                    <div className="formSectionOne">

                        {/*<div className="ssnWrapper">
                            <div className="ssnForm">
                                <label>Fyll i ditt personnummer <span className="mobileBreak"><br/></span>så hämtar vi dina uppgifter</label>

                                <div className="inputWrapper">
                                    <input disabled={this.state.busy ? "disabled" : "" } type="text" id="customerSSN" name="customerSSN" placeholder="YYYYMMDDNNNN" onKeyPress={this.ssnEnterHandler} onChange={this.handleChange} />
                                    <div data-am-button className={this.state.busy ? "loading" : ''}>
                                        <div className={this.state.busy ? "loading blue-button-nofade" : "blue-button-nofade"} onClick={this.personalNumberLookup}>
                                            <span className="ssnLabel">Hämta personuppgifter</span> <span className="fbi-icon-angle-right"></span>
                                        </div>
                                        {this.state.busy ?
                                            <Spinner size={30} color="blue" />
                                        : null}
                                    </div>
                                </div>

                                {this.state.ssnError ?
                                    <p className="ssnError">Det gick inte att hämta uppgifterna</p>
                                : null}

                            </div>
                        </div>*/}

                        {/*<div className="seperator"></div>*/}

                    </div>

                    <div className="formSectionTwo">
                        <div className="personFormWrapper">
                            <div className="personform">
                                <label>Namn</label>
                                <input name="formName"
                                    value={this.props.CbvStore.formName}
                                    className={(this.props.CbvStore.formName.indexOf(' ') < 0 || this.props.CbvStore.formName.length <= 0) && this.props.CbvStore.formError ? 'inputError' : ''}
                                    autoComplete="name"
                                    type="text"
                                    id="customerName"
                                    onChange={this.handleChange}
                                    placeholder="Namn"
                                />
                                {(this.props.CbvStore.formName.indexOf(' ') < 0 || this.props.CbvStore.formName.length <= 0) && this.props.CbvStore.formError ? <p>Ange ditt för och efternamn</p> : ''}
                            </div>

                            <div className="personform">
                                <label>Adress</label>
                                <input name="formAdress"
                                    value={this.props.CbvStore.formAdress}
                                    className={this.props.CbvStore.formAdress.length <= 0 && this.props.CbvStore.formError ? 'inputError' : ''}
                                    type="text"
                                    id="customerAdress"
                                    onChange={this.handleChange}
                                    placeholder="Adress"
                                />
                                {this.props.CbvStore.formAdress.length <= 0 && this.props.CbvStore.formError ? <p>Ange din adress</p> : null}
                            </div>

                            <div className="positionWrapper">
                                <div className="personform">
                                    <label>Postnummer</label>
                                    <input name="formZip"
                                        value={this.props.CbvStore.formZip}
                                        className={this.props.CbvStore.formZip.length <= 0 && this.props.CbvStore.formError ? 'inputError' : ''}
                                        type="text"
                                        id="customerZipcode"
                                        onChange={this.handleChange}
                                        placeholder="Postnummer"
                                        maxLength="5"
                                    />
                                    {this.props.CbvStore.formZip.length <= 0 && this.props.CbvStore.formError ? <p>Ange ditt postnummer</p> : null}
                                </div>

                                <div className="personform">
                                    <label>Ort</label>
                                    <input name="formPosition"
                                        value={this.props.CbvStore.formPosition}
                                        className={this.props.CbvStore.formPosition.length <= 0 && this.props.CbvStore.formError ? 'inputError' : ''}
                                        type="text"
                                        id="custommerPosition"
                                        onChange={this.handleChange}
                                        placeholder="Ort"
                                    />
                                    {this.props.CbvStore.formPosition.length <= 0 && this.props.CbvStore.formError ? <p>Ange din ort</p> : null}
                                </div>
                            </div>

                            <div className="seperator"></div>

                            <div className="formContactWrapper">
                                <div className="contactForm">
                                    <label>E-post</label>
                                    <input name="formEmail"
                                        value={this.props.CbvStore.formEmail}
                                        className={!this.validateEmail(this.props.CbvStore.formEmail) && this.props.CbvStore.formError ? 'inputError' : ''}
                                        autoComplete="email"
                                        type="email"
                                        id="customerEmail"
                                        onChange={this.handleChange}
                                        placeholder="E-post"
                                    />
                                    {!this.validateEmail(this.props.CbvStore.formEmail) && this.props.CbvStore.formError ? <p>Ange din e-postadress</p> : null}
                                </div>

                                <div className="contactForm">
                                    <label>Telefonnummer</label>
                                    <input name="formPhone"
                                        value={this.props.CbvStore.formPhone}
                                        className={this.props.CbvStore.formPhone.length <= 0 && this.props.CbvStore.formError ? 'inputError' : ''}
                                        autoComplete="tel"
                                        type="text"
                                        id="custommerPhone"
                                        onChange={this.handleChange}
                                        placeholder="Telefonnummer"
                                     />
                                     {this.props.CbvStore.formPhone.length <= 0 && this.props.CbvStore.formError ? <p>Ange ditt telefonnummer</p> : null}
                                </div>
                            </div>

                            <div className="seperator"></div>
                            
                            <Retailers></Retailers>

                        </div>

                    </div>

                </form>

                <div className="gdprinject" data-gdpr="consent,dealer" style={{paddingTop: '10px', textAlign: 'center'}}>
                    <p dangerouslySetInnerHTML={{__html: this.state.gdprText}}></p>
                </div>

                { this.props.CbvStore.formError ?
                    <p className="formErrorText"
                        style={{color: '#ee5661', textAlign: 'center', paddingTop: '10px', fontFamily: 'Volvo Novum Regular'}}>
                        Någonting gick fel. Kontrollera formuläret och försök att skicka din förfrågan igen. Lyckas du ändå inte, kontakta oss på <a href="mailto:sweden@volvocars.com">sweden@volvocars.com</a>
                    </p>
                : null }

            </div>
        );
    }
}

export default Form;
