import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import Api from '../../Api';
import { Text, TextInput, Button } from 'vcc-ui';
import Hero from './Hero';
import axios from 'axios';
import qs from 'qs';
import './styles.scss';

@inject('CbvStore')
@observer
class CbvStart extends Component {
    constructor(props) {
		super(props);
        this.anchorRef = React.createRef();

        this.state =  {
            formName: '',
            formEmail: '',
            formTel: '',
            formError: false,
            formSent: false,
            gdprText: ''
        }
    }

    componentDidMount = () => {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var sellerID = url.searchParams.get("seller");

        // Getting seller data
        if(sellerID) {
            Api.makeGet(`/seller/${sellerID}`)
            .then(data => {
                this.props.CbvStore.sellerID = data.csid;
                this.props.CbvStore.sellerHash = data.hash;
                this.props.CbvStore.sellerEmail = data.email;
            })
            .catch(err => { console.log(err) })
        }

        axios.get('https://dms.fbinhouse.se/api/266/?campaign=compliance&tag=consent')
        .then(data => {
            this.setState({
                gdprText: data.data[0].values.compliancetext.paragraphs[0].text
            })
        })

        // tracking
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                'event': 'interaction',
                'category': 'lead_form-B2C-KMU---CBV-{CBVKMU}',
                'action': 'WebForm',
                'label': 'load'
            });
        }

    }

    validateEmail = (email) => {
        const rule = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
        return rule.test(email);
    }

    validateForm = () => {
        if(this.state.formName.length > 1 && (this.validateEmail(this.state.formEmail) || this.state.formTel.length >= 5)) {
            this.setState({
                formError: false
            })

            this.submitForm();

        } else {
            this.setState({
                formError: true
            })
        }
    }

    submitForm = () => {
        axios({
            method: 'post',
            url: 'https://www.clickredirect.net/_net4/volvo/Enkat_logg_mailer_stream/enkat_logg_mailer.aspx',
            data: qs.stringify({
                RBDescription: 'Care by Volvo Interest Request',
                name: this.state.formName,
                email: this.state.formEmail,
                phone: this.state.formTel
            })
        })
        .then(result => {
            this.setState({
                formSent: true
            })

            // tracking
            if (typeof window !== 'undefined' && window.dataLayer) {
                window.dataLayer.push({
                    'event': 'interaction',
                    'category': 'lead_form-B2C-KMU---CBV-{CBVKMU}',
                    'action': 'WebForm',
                    'label': 'success'
                });
            }

        })
        .catch(error => {
            console.log("error: " + error);
            this.setState({
                formSent: false
            })
        })
    }


    render() {
        return (
            <div className="startPageWrapper">
                <Hero></Hero>

                <div className="formWrapper">
                    <Text variant="peary">Care by Volvo</Text>
                    <Text style={{textAlign: 'center', marginTop: '25px'}}>
                        Vårt flexibla bilabonnemang, utan lång bindningstid och med fast månadskostnad. 
                        Lanseras i första delen av 2021, anmäl ditt intresse här så hör vi av oss när det är dags.
                    </Text>

                    { !this.state.formSent 
                        ? <form>
                            <TextInput
                                className={this.state.formError === true && this.state.formName.length <= 1 ? 'formInput inputError' : 'formInput'}
                                value={this.state.formName}
                                label="Namn"
                                onChange={e => {
                                    this.setState({formName: e.target.value})
                                }}
                            />

                            <TextInput
                                className={this.state.formError === true && !this.validateEmail(this.state.formEmail) ? 'formInput inputError' : 'formInput'}
                                value={this.state.formEmail}
                                label="E-post"
                                type="email"
                                onChange={e => {
                                    this.setState({formEmail: e.target.value})
                                }}
                            />

                            <TextInput
                                className={this.state.formError === true && this.state.formTel.length < 5 ? 'formInput inputError' : 'formInput'}
                                value={this.state.formTel}
                                label="Telefonnummer"
                                type="tel"
                                onChange={e => {
                                    this.setState({formTel: e.target.value})
                                }}
                            />

                            <p dangerouslySetInnerHTML={{__html: this.state.gdprText}} style={{textAlign: 'left', paddingTop: '5px'}}></p>
                        </form>
                        
                        : <div> 
                            <Text variant="ootah" style={{marginTop: '50px'}}>
                                Tack för att du har <span className="mobileBreak"><br/></span> anmält intresse! <br/>
                            </Text>
                            <Text style={{fontSize: '18px',marginTop: '10px'}}>
                                 Vill du ha en bil redan nu? <span className="mobileBreak"><br/></span> Kolla in <a href="https://www.volvocars.com/se/kop/finansiering-och-tjanster/privat/privatleasing">Volvo privatleasing</a>.
                            </Text>
                        </div>
                    }
                    
                    { (this.state.formError && this.state.formName.length > 1) && (this.validateEmail(this.state.formEmail) || this.state.formTel.length < 5) ?
                        <Text className="inputContactError">
                            Vänligen fyll E-post eller telefonnummer.
                        </Text>
                    : null }

                    { !this.state.formSent 
                        ? <div className="buttonWrapper" style={{maxWidth: '300px', margin: '0 auto', marginTop: '40px'}}>
                            <Button onClick={() => this.validateForm()}>Skicka</Button>
                        </div> 
                        : null
                    }
                    

                </div>

            </div>
        );
    }
}

export default CbvStart;
