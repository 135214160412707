import React, {Component} from 'react';
import Slider from "react-slick";
import {inject, observer} from 'mobx-react';
import posed from 'react-pose';

import './styles.scss';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
          className={className}
          onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
          className={className}
          onClick={onClick}
        />
    );
}

const Modal = posed.div({
    enter: { x: "0", opacity: 1, delay: 0, transition: {type: 'spring', stiffness: 210, damping: 22} },
    exit: { x: "-300px", opacity: 0, delay: 0, transition: {type: 'spring', stiffness: 210, damping: 22} }
});

@inject('CbvStore')
@observer
class SummarySlider extends Component {
    render() {
        const {CbvStore} = this.props;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            //lazyLoad: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        if(!CbvStore.carData) {
            return <div className="pageLoader"></div>
        }

        let carImages = CbvStore.carImages.length > 0 ? CbvStore.carImages : CbvStore.carData.images;

        return (
            <div className="SummarySliderWrapper">
                <Modal className="step3CarPose sliderWrapper">
                    <Slider {...settings}>
                        {carImages.map((image, index) =>
                            <div key={index}>
                                <img src={image} alt="" />
                            </div>
                        )}
                    </Slider>
                </Modal>
            </div>
        );
    }
}

export default SummarySlider;
