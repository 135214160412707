import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
//import PriceFormat from '../../../utils/PriceFormat';
import './styles.scss';

@inject('CbvStore')
@observer
class Hero extends Component {
    render() {
       // const {Link} = this.props.CbvStore;

        return (
            <div className="hero-section">
                <div className="heroWrapper">
                    <div className="hero-section-text">
                        {/*<h1>Bilabonnemang <br/> med Care by Volvo</h1>

                        <ul>
                            <li>Bil till fast månadskostnad.</li>
                            <li>Fri service och försäkring som håller dig trygg.</li>
                        </ul>

                        <div className="heroPriceWrapper">
                            <p className="heroPriceTitle">V90 D4 AWD Momentum Special Edition</p>
                            <p className="heroPriceText"><strong>{PriceFormat('4795', ' ', 'X')} kr/mån</strong></p>
                            <p>36 månader, 1 500 mil/år</p>
                        </div>

                        <div data-am-button>
                            <Link to={"/valj-modell"} title="" className="blue-button-nofade">
                                Välj din bil
                            </Link>
                        </div>*/}

                    </div>
                </div>
            </div>
        );
    }
}

export default Hero;
