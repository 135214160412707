import React, {Component} from 'react';
import './styles.scss';
import CbvCar from './CbvCar';
//import ContactMe from '../../ContactMe';

class CbvCars extends Component {

    render() {
        return (
            <div className="cbvCarsContainer">

                {this.props.cars.map((car, index) => {
                    return <React.Fragment key={index}>
                            <CbvCar key={index}
                                price={this.props.cars[index].price}
                                name={this.props.cars[index].name}
                                title={this.props.cars[index].title}
                                id={this.props.cars[index]._id}
                                carModel={this.props.cars[index].carModel.toLowerCase()}
                                images={this.props.cars[index].images}
                                tags={this.props.cars[index].tags}
                                >
                            </CbvCar>

                            {/*index === 1 ? <ContactMe></ContactMe> : null*/}

                    </React.Fragment>
                })}

            </div>
        );
    }
}

export default CbvCars;
