import React, {Component} from 'react';
import posed from 'react-pose';
import {inject, observer} from 'mobx-react';

import './styles.scss';

const IncludedPopupWrapper = posed.div({
  hidden: {
      opacity: 0,
      transition: {duration: 300},
      applyAtEnd: {display: 'none'}
  },
  visible: {
      opacity: 1,
      delay: 0,
      transition: {duration: 300},
      applyAtStart: {display: 'block'}
  }
});


@inject('CbvStore')
@observer
class IncludedPopup extends Component {

    render() {
        return (
            <IncludedPopupWrapper className="includedPopup" pose={this.props.showIncludedInfo ? 'visible' : 'hidden'}>
                <div className="close" onClick={() => this.props.closeIncludedInfo()}></div>
                <div className="innerWrapper">
                    <h2 style={{'lineHeight': 'normal', 'fontFamily': 'Volvo Novum Light'}}>Utöver din valda bil och specifikation ingår följande i ditt Care by Volvo abonnemang</h2> <br />
                    <h2>Volvia försäkring</h2>
                    <p>
                        Ditt abonnemang innehåller en halvförsäkring som tillsammans med nybilsgarantin motsvarar en helförsäkring.
                    </p>

                    <h2>Trygghetspaket</h2>
                    <p>
                        Som en extra trygghet får du bl.a. hyrbilsförsäkring, nyckelförsäkring,
                        ersättning för skador inne i bilen och 0 kronor i självrisk vid djurkollision.
                    </p>

                    <h2>Service</h2>
                    <p>
                        Vi ser till att din bil genomgår service på en tid som passar dig. Alltid hos en auktoriserad verkstad och med en personlig servicetekniker.
                    </p>

                    <h2>Volvo Assistans</h2>
                    <p>
                        Om du får problem med din bil lovar vi att hjälpa dig på vägen. Dygnet runt och i alla västeuropeiska länder.
                    </p>

                    <h2>Hämta och lämna vid service</h2>
                    <p>
                        För dig som bor i Göteborg, Malmö eller Stockholmsområdet kan vi hämta och lämna din
                        bil på en plats som passar dig när det är dags för Volvo Originalservice.
                    </p>

                    <h2>Volvokort med Carpay</h2>
                    <p>
                        Du får bonus på alla dina köp och rabatt när du tankar och tvättar.
                        I appen CarPay har du full koll på din bilekonomi.
                    </p>

                    <h2>Klimatpaket med Volvo on Call</h2>
                    <p>
                        Du ser bilens position på en karta, kan öppna och låsa dörrar,
                        se hur mycket bränsle du har kvar och, inte minst, starta värmaren med mobilen.
                    </p>

                    <h2>Rabatt hos Hertz</h2>
                    <p>
                        Familjebuss till weekendresan, extrabil över sommaren eller nåt helt annat.
                        Med Care by Volvo får du 20 procent rabatt på hyrbil hos Hertz i Sverige.
                    </p>

                    <h2>Fritt leasingskydd i 3 månader</h2>
                    <p>
                        Privatleasingskydd är en försäkring som träder in om du blir sjuk
                        eller arbetslös och som ersätter leasingkostnaden för din bil i upp till 12 månader.
                    </p>
                </div>
            </IncludedPopupWrapper>
        );
    }
}

export default IncludedPopup;
