import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import posed from 'react-pose';
import Api from '../../../Api';

import CarSlider from './Slider';
import IncludedPopup from '../IncludedPopup';
import EquipmentList from './EquipmentList';
import PriceFormat from '../../../utils/PriceFormat';

import './styles.scss';

const body = document.getElementsByTagName("body")[0];

const TillvalPopup = posed.div({
  hidden: {
      opacity: 0,
      transition: {duration: 300},
      applyAtEnd: {display: 'none'}
  },
  visible: {
      opacity: 1,
      delay: 0,
      transition: {duration: 300},
      applyAtStart: {display: 'block'}
  }
});

const ExtaTillvalPopup = posed.div({
    hideExtraTillval: {
        opacity: 0,
        transition: {duration: 300},
    },
    showExtraTillval: {
        opacity: 1,
        delay: 0,
        transition: {duration: 300},
    }
})

const Modal = posed.div({
    closed: {
        height: 'auto',
        delay: 400,
        transition: {duration: 300},
        onPoseComplete: function() {
            window.setTimeout(function() {
                document.getElementById('poseWrapper').style.overflow = 'visible';
            }, 700)
        }
    },
    open: { height: 0 },

});

const SpeechBubbleModal = posed.div({
    bubbleHidden: {
        opacity: 0,
        y: '-20%',
        transition: {type: 'spring', stiffness: 200, damping: 18},
        applyAtEnd: {display: 'none'},
    },
    bubbleVisible: {
        opacity: 1,
        y: '0%',
        transition: {type: 'spring', stiffness: 200, damping: 18},
        applyAtStart: {display: 'block'}
    }
});

const TextModal1 = posed.div({
    text1Hidden: { x: "100%", opacity: 0, display: 'none', transition: {type: 'spring', stiffness: 210, damping: 22, delay: 450} },
    text1Visible: { x: "0", opacity: 1, display: 'block', transition: {type: 'spring', stiffness: 210, damping: 22, delay: 450} }
});

const TextModal2 = posed.div({
    text2Hidden: { x: "-100%", opacity: 0, transition: {type: 'spring', stiffness: 210, damping: 22, delay: 450} },
    text2Visible: { x: "0", opacity: 1, transition: {type: 'spring', stiffness: 210, damping: 22, delay: 450} }
});

@inject('CbvStore')
@observer
class Modell extends Component {
    constructor(props) {
        super(props);
        this.lightBoxToggle = this.lightBoxToggle.bind(this);

        this.state = {
            showPopup: false,
            showIncludedInfo: false,
            colorToggled: [],
            tillvalToggled: [],
            'price': '',
            speechBubble: true,
            showExtraTillval: false
        };
    }


    componentDidMount() {
        Api.makeGet(`/car/${this.props.CbvStore.id}`)
            .then(data => {
                this.props.CbvStore.carData = data;
                this.props.CbvStore.fetchedApiData = true;

                if(!this.props.CbvStore.carPrice || this.props.CbvStore.carPrice <= 0) {
                    this.props.CbvStore.carPrice = data.price[0].basePrice.toString();
                }

                this.props.CbvStore.carStartPrice = data.price[0].basePrice.toString();

                this.props.CbvStore.carCis = data.ciscode;

                // Tillval
                data.price[0].additional.map((item, index) => {
                    const propertyName = item.name;
                    if(!this.props.CbvStore.dynamicTillval.hasOwnProperty([propertyName])) {
                        this.props.CbvStore.dynamicTillval[propertyName] = false
                    }

                    return this.props.CbvStore[item.name] ? null : this.props.CbvStore[propertyName] = false
                })

                // Selected color
                data.price[0].colors.forEach((item, index) => {
                    if(item.default === true) {

                        if(this.props.CbvStore.colorSelected === false) {

                            this.props.CbvStore.selectedColor = {
                                cis: item.cis,
                                name: item.name.replace(/[0-9]/g, ''),
                                price: item.price,
                                icon: item.icon ? item.icon : 'https://via.placeholder.com/30',
                                default: item.default
                            }
                        }

                        // Adding color price
                        if(this.props.CbvStore.carPrice === this.props.CbvStore.carStartPrice) {
                            this.props.CbvStore.addToCarPrice(item.price)
                        }
                    }
                })

            })
            .catch(err => { console.log(err) })

    }

    togglePopup = (e, i) => {
        this.setState({showPopup: !this.state.showPopup})
        let tillvalToggled = [...this.state.tillvalToggled];
        tillvalToggled[i] = !tillvalToggled[i];
        this.setState({tillvalToggled});

        body.classList.add('disableScroll')
    }

    toggleExtraTillval = () => {
        console.log('clicked');
        this.setState({
            showExtraTillval: true
        })
    }


    toggleIncludedInfo = () => {
        this.setState({showIncludedInfo: !this.state.showIncludedInfo})
        this.setState({showPopup: !this.state.showPopup})

        body.classList.add('disableScroll');
    }

    lightBoxToggle() {
        this.setState({showPopup: false})
        this.setState({showIncludedInfo: false})
        this.setState({showExtraTillval: false})
        let tillvalToggled = [];
        this.setState({tillvalToggled});

        body.classList.remove('disableScroll');
    }

    closeTillvalInfo = () => {
        this.setState({showPopup: false})
        this.setState({showIncludedInfo: false})
        this.setState({showExtraTillval: false})
        let tillvalToggled = [];
        this.setState({tillvalToggled});

        body.classList.remove('disableScroll')
    }

    toggleColor(event, i) {
        let startCarPrice = this.props.CbvStore.carStartPrice;
        let colorPrice = event.target.dataset.price;
        let newPrice = Number(colorPrice) + Number(startCarPrice);

        // Toggles
        let colorToggled = [...this.state.colorToggled];

        colorToggled.forEach(function(item, i) {
            colorToggled[i] = false
        })

        colorToggled[i] = !colorToggled[i];
        this.setState({colorToggled});

        // Selected color to CbvStore
        let selectedColor = {
            cis: event.target.dataset.cis,
            name: event.target.dataset.label,
            price: event.target.dataset.price,
            icon: event.target.src,
            default: false,
            selected: true
        }

        this.props.CbvStore.selectedColor = selectedColor;
        this.props.CbvStore.colorSelected = true;


        // Calc new price including tillval
        this.props.CbvStore.tillval.forEach(function(item, i) {
            if(item.checked === true) {
                newPrice = newPrice + Number(item.price);
            }
        })

        this.props.CbvStore.carPrice = newPrice.toString();

        // Adding selected color to cis code
        this.props.CbvStore.carCis = this.props.CbvStore.carCis.substring(0, 14) + this.props.CbvStore.selectedColor.cis + this.props.CbvStore.carCis.substring(17);

        var cis = this.props.CbvStore.carCis;
        var hostImg = 'https://static.fbinhouse.se/sparkProxy/';
        var imgsize = '1200x/';
        var interior = '/INTERIOR/';
        var exterior = '/EXTERIOR/';

        var imageArr = [];

        for(var x = 1; x < 10; x++) {
          if (x < 7) {
            imageArr.push(hostImg + imgsize + cis + exterior + x + '.png')
          } else {
            imageArr.push(hostImg + imgsize + cis + interior + (x - 6)  + '.png')
          }
        }

        this.props.CbvStore.carImages = imageArr;
    }

    handleTillvalCheck(event) {
        const {checked, value, name} = event.target;
        const tillvalID = event.target.getAttribute('tillvalid');
        // apparently if value is 0, the checkbox wont rerender. So setting a pointless state.
        this.setState({
            price: value
        })

        this.props.CbvStore[name] = checked;

        this.props.CbvStore.dynamicTillval[name] = checked;

        if(checked === true) {
            this.props.CbvStore.addToCarPrice(value)

            this.props.CbvStore.tillval.push( {checked: checked, name: name, price: value, id: tillvalID ? tillvalID : 'custom'} );
        } else {
            // Remove from tillval array
            this.props.CbvStore.removeFromTillval(name, value)
        }
    }

    CreateMarkup(text) {
      return {__html: text};
    }

    ClearText(text) {
      return <span dangerouslySetInnerHTML={this.CreateMarkup(text)} />;
    }

    navigateToNextStep() {
        if(!this.props.CbvStore.navigatedLastStep && this.props.CbvStore.tillval.length === 0 && this.props.CbvStore.carData.price[0].additional.length > 0) {
            if(this.props.CbvStore.tillval.length === 0 && this.state.speechBubble) {
                this.setState({
                    speechBubble: false
                })
            }
            if(!this.state.speechBubble) {
                this.props.CbvStore.navigate(`/slutfor/${this.props.CbvStore.carData.carModel.toLowerCase()}/${this.props.CbvStore.id}`);
                this.props.CbvStore.navigatedLastStep = true;
            }
            if (window.innerHeight < 960) {
                this.anchorScroll('step2Wrapper', 50);
            }
            if (window.innerWidth < 500) {
                this.anchorScroll('step2Wrapper', -50);
            }

        } else {
            this.props.CbvStore.navigate(`/slutfor/${this.props.CbvStore.carData.carModel.toLowerCase()}/${this.props.CbvStore.id}`);
            this.props.CbvStore.navigatedLastStep = true;
        }
    }

    scrollToAnim = (scrollTarget) => {
        var duration = 600;
        var y = scrollTarget;
        var initialY = document.documentElement.scrollTop || document.body.scrollTop;
        var baseY = (initialY + y) * 0.5;
        var difference = initialY - baseY;
        var startTime = performance.now();

        function step() {
            var normalizedTime = (performance.now() - startTime) / duration;
            if (normalizedTime > 1) normalizedTime = 1;

            window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));
            if (normalizedTime < 1) window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
    }

    anchorScroll = (scrollTarget, targetOffset) => {
        function offset(el) {
            var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return {
                top: rect.top + scrollTop, left: rect.left + scrollLeft
            }
        }

        var targetScroll = offset(document.getElementById(scrollTarget)).top - targetOffset

        this.scrollToAnim(targetScroll);
    }

    render() {
        const {CbvStore} = this.props;

        if(!CbvStore.carData) {
            return <div className="pageLoader"></div>
        }
        const tillvalEl = [];

        CbvStore.carData.price[0].additional.forEach(( val, key ) => {
            let name = CbvStore.carData.price[0].additional[key].name;
            let description = CbvStore.carData.price[0].additional[key].description;
            let price = CbvStore.carData.price[0].additional[key].price;
            let checked = CbvStore.dynamicTillval[name];
            let tillvalid = CbvStore.carData.price[0].additional[key].id;

            tillvalEl.push(<div className="checkBoxWrapper" key={name}>
                <div className="labelWrapper">
                    <label className="container">{CbvStore.carData.price[0].additional[key].name}

                        <input type="checkbox" name={name} tillvalid={tillvalid} value={price} checked={checked ? checked : false} onChange={(e) => this.handleTillvalCheck(e)} />

                        <span className="checkmark"></span>
                    </label>

                    { description.length > 1 ?
                        <span onClick={(e) => this.togglePopup(e, key)} className="fbi-icon-info"></span>
                    : null }

                </div>
                <div className="textWrapper">
                    <span className="tillvalCost">+{price} kr/mån</span>
                </div>

                <TillvalPopup className={this.state.tillvalToggled[key] ? "infoPopup active" : "infoPopup"} pose={this.state.tillvalToggled[key] ? 'visible' : 'hidden'}>
                    <h5>{name}</h5>
                    <p>{this.ClearText(description)}</p>
                    <div className="close" onClick={() => this.closeTillvalInfo()}></div>
                </TillvalPopup>

            </div>)
        });

        return (
            <div className="step2Wrapper" id="step2Wrapper">

                <div className="carWrapper">
                    <div className="textWrapper">
                        <h2>{CbvStore.carData.name}</h2>
                        <p className="subtitle">{CbvStore.carData.title}</p>
                    </div>

                    <CarSlider
                        images={CbvStore.carData.images}
                    ></CarSlider>
                </div>

                <div className="optionsWrapper">

                    <Modal initialPose="open" pose="closed" className="poseWrapper" id="poseWrapper">
                        <div className="colorsWrapper">
                            <p className="colorsTitle">Färger</p>

                            {CbvStore.carData.price[0].colors.map((item, index) => (
                                <div className="colorWrapper" key={index}>
                                    <img onClick={(e) => {this.toggleColor(e, index)}}
                                        src={item.icon ? item.icon : "https://via.placeholder.com/30"}
                                        data-label={item.name.replace(/[0-9]/g, '')}
                                        data-price={item.price} alt=""
                                        data-cis={item.cis}
                                        className={item.name.replace(/[0-9]/g, '') === CbvStore.selectedColor.name ? 'selected' : ''}>
                                    </img>
                                </div>
                            ))}

                            <p className="selectedColor">{CbvStore.selectedColor.name} <strong>+{CbvStore.selectedColor.price} kr/mån</strong></p>
                        </div>

                        {tillvalEl.length > 0 ?
                        <div className={!this.props.CbvStore.navigatedLastStep && !this.state.speechBubble ? "tillValWrapper tillvalWarning" : "tillValWrapper"}>
                            <p className="tillvalTitle">Populära tillval</p>
                            {tillvalEl}
                        </div>
                        : null}

                        <p className="extraTillval" onClick={this.toggleExtraTillval}>Saknar du något tillval?</p>

                    </Modal>

                    <div className="priceWrapper" style={{marginTop: tillvalEl.length === 0 ? '0': ''}}>
                        <div className="cbvPrice">
                            <h2 onClick={this.toggleIncludedInfo}>
                                <span className="totalCarPrice">{CbvStore.getCarPrice}</span> kr/mån
                                <span className="fbi-icon-info cbvIncludedInfo"></span>
                            </h2>
                            <p>{CbvStore.carData.price[0].months} månader. {PriceFormat(CbvStore.carData.price[0].mileage.toString(), ' ', 'x')} mil/år</p>
                        </div>

                        <div data-am-button onClick={() => this.navigateToNextStep()}>
                            <div className="blue-button-nofade">

                                <TextModal1 className="TextModal1" initialPose="text1Visible" pose={this.state.speechBubble ? 'text1Visible' : 'text1Hidden'}>
                                    <p>Ange personuppgifter</p>
                                </TextModal1>

                                <TextModal2 className="TextModal2" initialPose="text2Hidden" pose={this.state.speechBubble ? 'text2Hidden' : 'text2Visible'}>
                                    <p>{this.props.CbvStore.tillval.length === 0 ? "Fortsätt utan några tillval" : "Ange personuppgifter"}</p>
                                </TextModal2>

                            </div>
                        </div>
                    </div>

                    {this.state.showExtraTillval ?
                        <ExtaTillvalPopup className="extraInfoPopup" initialPose="hideExtraTillval" pose={this.state.showExtraTillval ? 'showExtraTillval' : 'hideExtraTillval'}>
                            <h5>Lägg till mer senare</h5>
                            <p>Innan ditt abonnemang startar kommer vi att kontakta dig. Då har du chansen att anpassa din beställning och göra fler tillval.</p>
                            <div className="close" onClick={() => this.closeTillvalInfo()}></div>
                        </ExtaTillvalPopup>
                    : null}

                    {this.props.CbvStore.tillval.length === 0 ?
                        <SpeechBubbleModal className="speechBubbleModal" pose={this.state.speechBubble ? 'bubbleHidden' : 'bubbleVisible'} initialPose="bubbleHidden">
                            <div className="speech-bubble">
                                Vill du fortsätta utan några tillval?
                            </div>
                        </SpeechBubbleModal>
                    : null}

                </div>

                <EquipmentList></EquipmentList>

                {CbvStore.carData.techData ?
                  <div className="techData">{CbvStore.carData.techData}</div>
                : null}

                <IncludedPopup
                    showIncludedInfo={this.state.showIncludedInfo}
                    closeIncludedInfo={this.closeTillvalInfo}>
                </IncludedPopup>

                {this.state.showPopup || this.state.showExtraTillval ?
                    <div className="lightboxOverlay" onClick={this.lightBoxToggle}></div>
                    : null
                }

            </div>
        );
    }
}

export default Modell;
