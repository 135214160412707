import React, {Component} from 'react';
import './styles.scss';
import axios from 'axios';

class ContactMe extends Component {
    constructor(props) {
		super(props);
        this.anchorRef = React.createRef();
        this.state = {
            error: '',
            formSent: false,
            fields: {
                customerName: '',
                customerTel: '',
                customerMail: ''
                //customerMessage: ''
            }
        }
	}

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({
            formSent: true
        })

        axios({
            method: 'post',
            url: window.WEBPACK_PUBLIC + 'sendMail',
            headers: { 'content-type': 'application/json' },
            data: {
              customer_name: this.state.fields.customerName,
              customer_tel: this.state.fields.customerTel,
              customer_email: this.state.fields.customerMail
              //customer_message: this.state.fields.customerMessage
            }
        })
        .then(result => {
            console.log('mail sent');

            // Tracking
            if (typeof window !== 'undefined' && window.dataLayer) {
                window.dataLayer.push({
                    'event':'EventTracking',
                    'category':'Leads Private',
                    'action':'Care by Volvo',
                    'label':'Kontaktformulär'
                });
            }

        })
        .catch(error => this.setState({ error: error.message }))
    }

    handleFormChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    render() {
        return (
            <div className="contactMeContainer">
                <div className={this.state.formSent ? 'contactMeInnerWrapper hideForm' : 'contactMeInnerWrapper'}>
                    <div className="headerContainer">
                        <h2>Behöver du hjälp med att välja bil?</h2>
                        <p>Låt en av våra produktexperter kontakta dig.</p>
                    </div>

                    <form data-am-form method="post" onSubmit={this.onSubmit}>
                    	<div data-am-input>
                    		<input required onChange={this.handleFormChange.bind(this, "customerName")} type="text" placeholder="Namn" name='customer_name' />
                    	</div>
                        <div data-am-input>
                            <input required onChange={this.handleFormChange.bind(this, "customerTel")} type="number" placeholder="Telefonnummer" name="customer_tel" />
                        </div>
                        <div data-am-input>
                            <input required onChange={this.handleFormChange.bind(this, "customerMail")} type="email" placeholder="E-post" name='customer_email' />
                        </div>

                    	{/*<div data-am-textarea>
                    		<textarea required onChange={this.handleFormChange.bind(this, "customerMessage")} placeholder="Berätta vad du söker..." name="customer_message"></textarea>
                    	</div>*/}

                        <input type="submit" value="Kontakta mig" className="blue-button" />
                    </form>
                </div>

                <div className={this.state.formSent ? 'confirmMessage' : 'confirmMessage hideMessage'}>
                    <h2>Tack för ditt meddelande.</h2>
                    <p>Vi kommer att höra av oss inom kort.</p>
                </div>
            </div>
        );
    }
}

export default ContactMe;
