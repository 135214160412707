import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import posed from 'react-pose';

import './styles.scss';

const body = document.getElementsByTagName("body")[0];

const FeatureText = posed.div({
  hidden: {
      opacity: 0,
      transition: {duration: 300},
      applyAtEnd: {display: 'none'}
  },
  visible: {
      opacity: 1,
      delay: 0,
      transition: {duration: 300},
      applyAtStart: {display: 'block'}
  }
});

const LightBox = posed.div({
  hidden: {
      opacity: 0,
      transition: {duration: 300},
      applyAtEnd: {display: 'none'}
  },
  visible: {
      opacity: 0.5,
      transition: {duration: 300},
      applyAtStart: {display: 'block'}
  }
});

@inject('CbvStore')
@observer
class EquipmentList extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)

        this.state = {
            featureTextOpen: false,
            selected: false,
            currentItemIndex: 0
        };
    }

    handleClick(i) {
        // Get items
        let items = this.props.CbvStore.carData.equipment;
        items[i].selected = true;

        // Set State
        this.setState({
          selected: true
        })

        this.setState({
            currentItemIndex: i
        })

        body.classList.add('disableScroll')
    }

    closeFeatureText() {
        // Get items
        let i = this.state.currentItemIndex;
        let items = this.props.CbvStore.carData.equipment;

        items[i].selected = false;

        // Set State
        this.setState({
          selected: false
        })

        body.classList.remove('disableScroll')
    }

    render() {
        const {CbvStore} = this.props;

        if(!CbvStore.carData) {
            return <div></div>
        }

        return (
            <div className="equipmentListWrapper">
                <h2>Utrustning</h2>
                <div className="equipmentList">
                    <div className="listWrapper">
                        {CbvStore.carData.equipment.map((item, i) =>
                            <React.Fragment key={i}>
                                {item.additional
                                ? <p onClick={() => this.handleClick(i)} className='additional' key={i}>{item.description}</p>
                                : <p key={i}>{item.description}</p>}

                                {item.additional ?
                                    <FeatureText className="featureBoxWrapper" pose={item.selected ? 'visible' : 'hidden'}>
                                        <div className="featureTextBox" >
                                            <p className="featureTextHeader">{item.description}</p>
                                            <p className="featureText">{item.additional}</p>
                                        </div>
                                        <div className="close" onClick={() => this.closeFeatureText()}></div>
                                    </FeatureText>
                                : null}
                            </React.Fragment>
                        )}
                    </div>
                </div>

                <LightBox className="lightboxWrapper" pose={this.state.selected ? 'visible' : 'hidden'}>
                    <div onClick={() => this.closeFeatureText()} className="lightbox"></div>
                </LightBox>

            </div>
        );
    }
}

export default EquipmentList;
