import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import Hero from '../../components/CbvStart/Hero'
import './styles.scss';

@inject('CbvStore')
@observer
class FrequentQuestions extends Component {
    constructor(props) {
		super(props);
        this.anchorRef = React.createRef();
        this.state = {
           isToggled : [],
           car: null
        }
    }

    toggle(e, i) {
        if (e.target.className !== 'link') {
            let isToggled = [...this.state.isToggled];
            isToggled[i] = !isToggled[i];
            this.setState({isToggled});

            let textEle = e.currentTarget.querySelectorAll('p')[1];
            let textHeight = e.currentTarget.querySelectorAll('p')[1].scrollHeight;

            if(e.currentTarget.classList.contains('toggled')) {
                textEle.style.maxHeight = '0px';
            } else {
                textEle.style.maxHeight = textHeight + 'px';
            }
        }
    }

    render() {

        return (
            <>
                <div className="FrequentQuestionsWrapper">
                    <div className="ingress">
                        <h3>CARE BY VOLVO</h3>
                        <h2>Vanliga frågor och svar</h2>
                    </div>

                    <div className="cbvInfoBottom">

                        <div className="cbvInfoBottomInnerWrapper">
                            <div className={this.state.isToggled[0] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 0)}>
                                <p className="groupTitle">Hur fungerar Care by Volvo?<span className={this.state.isToggled[0] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Med Care by Volvo abonnerar du på din bil istället för att äga den. Du väljer Volvomodell, extrautrustning och vald körsträcka.
                                    Care by Volvo tecknas med en avtalstid på 24 eller 36 månader. Dina val ger dig din slutgiltiga månadskostnad där hyra för bilen,
                                    försäkring och service ingår.
                                </p>
                            </div>
                            <div className={this.state.isToggled[1] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 1)}>
                                <p className="groupTitle">Vad ingår i den månadsavgift som jag betalar?<span className={this.state.isToggled[1] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Du får mer med Care by Volvo än en vanlig privatleasing. I månadsavgiften ingår Volvia försäkring med trygghetspaket,
                                    Volvofinans privatleasingskydd de första tre månaderna, fri service, Volvo assistans, Hämta och Lämna vid service inom definierad region,
                                    Volvokort med CarPay, Volvo on Call, klimatpaket och 20% rabatt när du hyr bil hos Hertz.
                                </p>
                            </div>
                            <div className={this.state.isToggled[2] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 2)}>
                                <p className="groupTitle">Vilka försäkringar ingår?<span className={this.state.isToggled[2] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Din Volvo har 3 års personvagnsgaranti. Tillsammans med Volvia halvförsäkring som ingår är bilen helförsäkrad.
                                    Utöver personvagnsgaranti och halvförsäkring ingår även Volvias trygghetspaket.
                                    Vid alla försäkringsfrågor vänder du dig till Volvia. Vid skada kontaktar du Volvia på: 031-345 69 00. För att läsa mer gå till: <a href="https://www.volvia.se/globalassets/dokument/volvia-privat-villkor.pdf">https://www.volvia.se/globalassets/dokument/volvia-privat-villkor.pdf</a>
                                </p>
                            </div>
                            <div className={this.state.isToggled[3] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 3)}>
                                <p className="groupTitle">Vad ingår i trygghetspaketet?<span className={this.state.isToggled[3] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    • I Trygghetspaketet har vi samlat de mest efterfrågade tilläggsförsäkringarna hos Volvia.
                                    Hyrbil - du får hyrbil i upp till 60 dagar när din Volvo är på reparation för en skada som täcks av din bilförsäkring.
                                    Om du råkar ut för en skada och hyr bil betalar vi 75 % av dygns- och kilometerkostnaden.
                                    Är du utomlands kan du i samma situation få hyrbil i upp till 45 dagar. Vi betalar då hela dygns- och kilometerkostnaden.
                                    <br/><br/>
                                    • Nyckelförsäkring - det är lätt hänt att tappa bort bilnyckeln. Och med dagens avancerade låsteknik kan det tyvärr bli ganska kostsamt.
                                    Den här försäkringen ersätter förlorad eller skadad nyckel/nyckelkort.
                                    Ersättning lämnas för ny nyckel samt nödvändig om- eller avkodning med maximalt 6 000 kr innan avdrag för självrisken på 1 800 kronor.
                                    <br/><br/>
                                    • Olyckshändelse inne i bilen - det är lätt hänt att spill och kladd från glass och kaffe förstör bilen invändigt, eller att packning i bilen repar innertaket.
                                    Invändiga skador ersätts upp till 20 000 kronor på bilar upp till 8 år.
                                    <br/><br/>
                                    • Sänkt självrisk vid skadegörelse - inte lika dyrt med repor och sönderskurna däck. Du har endast 1 000 kronor i
                                    självrisk på vagnskadeförsäkringen eller personvagnsgarantin om din bil har blivit utsatt för skadegörelse.
                                    <br/><br/>
                                    • 0 kronor i vagnskadesjälvrisk vid kollision med djur - vid kollision med djur får du ersättning för skadorna på bilen, samt ersättning för skadade kläder.
                                    Du slipper att betala grundsjälvrisken för vagnskadeförsäkringen eller personvagnsgarantin.
                                    Max ersättning för självrisk och kläder är 10 000 kronor.
                                    <br/><br/>
                                    • Ingen självrisk vid vagnskada utomlands - vid olycka utomlands, med ett utländskt registrerat fordon,
                                    slipper du betala självrisken för din Personvagnsgaranti eller vagnskadeförsäkring,
                                    om vi kan ersätta händelsen och om din bil är yngre än 10 år. Du måste ha registreringsnumret på det andra fordonet.
                                </p>
                            </div>
                            <div className={this.state.isToggled[4] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 4)}>
                                <p className="groupTitle">Vilka kostnader tillkommer?<span className={this.state.isToggled[4] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">Kostnader för registreringsavgift, böter, trängselskatt, vägtrafikskatt och andra skatter och avgifter ingår ej i din månadskostnad.</p>
                            </div>
                            <div className={this.state.isToggled[5] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 5)}>
                                <p className="groupTitle">Får jag rabatt på drivmedel?<span className={this.state.isToggled[5] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">Ett Volvokort med extra drivmedelsrabatt ingår i Care by Volvo.
                                    Kortet är kostnadsfritt och du hanterar enkelt din bilekonomi i den tillhörande appen CarPay.
                                    Bland Volvokortets mest omtyckta förmåner hör räntefri delbetalning, specialerbjudanden hos din Volvohandlare,
                                    bra drivmedelsrabatt på Tanka och OKQ8 samt bonus på alla köp. Via CarPay kan du dessutom lösa in dina bonuspoäng mot drivmedel på Tanka.
                                </p>
                            </div>
                            <div className={this.state.isToggled[6] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 6)}>
                                <p className="groupTitle">Hur servar jag bilen?<span className={this.state.isToggled[6] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Bilen skall ha service enligt de intervall som anges i instruktionsboken. Volvo Originalservice ingår i månadsavgiften och du bokar in dig hos din valda Volvohandlare.
                                    Det är mycket viktigt att du bokar in och utför service enligt de tid- och mil intervall som gäller för din bilmodell.
                                </p>
                            </div>
                            <div className={this.state.isToggled[7] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 7)}>
                                <p className="groupTitle">Vem kan teckna Care by Volvo?<span className={this.state.isToggled[7] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">Du måsta vara minst 18 år, svensk medborgare, ha bilkörkort och bli godkänd i den kreditkontroll som Volvo genomför.</p>
                            </div>
                            <div className={this.state.isToggled[8] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 8)}>
                                <p className="groupTitle">Kan jag köra utomlands?<span className={this.state.isToggled[8] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Ja, du kan köra bilen i hela Europa med undantag länder som saknar gröna kort (särskilt försäkringsbevis).
                                    För länder som saknar grönt kort gäller särskilda regler vilket betyder att du måste teckna en gränsförsäkring.
                                    Om landet inte är medlemsstat i FN gäller inte din bilförsäkring och en särskild trafikförsäkring måste köpas vid gränsen.
                                    Om du vill läsa mer om gröna kort besök Trafikförsäkringsföreningens hemsida.
                                </p>
                            </div>
                            <div className={this.state.isToggled[9] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 9)}>
                                <p className="groupTitle">Får jag röka i bilen?<span className={this.state.isToggled[9] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Nej. Rökning är inte tillåtet i din Care by Volvo.
                                </p>
                            </div>
                            <div className={this.state.isToggled[10] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 10)}>
                                <p className="groupTitle">Får jag ha djur i bilen?<span className={this.state.isToggled[10] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Ja. Bilen måste dock utrustas med lämplig skydd/bur utan att det medför några ändringar av bilen eller dess utrustning.
                                    Du ansvarar också för att återställa bilen i ursprungligt skick inför återlämnandet.
                                </p>
                            </div>
                            <div className={this.state.isToggled[11] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 11)}>
                                <p className="groupTitle">Kan jag bryta kontraktet i förtid?<span className={this.state.isToggled[11] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Ja, vid särskilda omständigheter, se allmänna villkor. En slutberäkning kommer genomföras vilket definierar det kvarvarande belopp som måste inbetalas.
                                </p>
                            </div>
                            <div className={this.state.isToggled[12] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 12)}>
                                <p className="groupTitle">Hur gör jag om jag krockar?<span className={this.state.isToggled[12] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Kontakta ditt försäkringsbolag Volvia. <a href="tel:0313456900">031-345 69 00</a>
                                </p>
                            </div>
                            <div className={this.state.isToggled[13] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 13)}>
                                <p className="groupTitle">Vad händer om jag överskrider min körsträcka?<span className={this.state.isToggled[13] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Vid övermil betalas mellanskillnaden om bilens verkliga mätarställning överstiger
                                    beräknad mätarställning utifrån avtalad årlig körsträcka efter abonnemangstidens
                                    slut med mer än 300 mil. Det som beräknas i mellanskillnaden är 6 kr per övermil.
                                </p>
                            </div>
                            <div className={this.state.isToggled[14] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 14)}>
                                <p className="groupTitle">Är självrisken högre om jag är under 24 år?<span className={this.state.isToggled[14] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Om föraren är under 24 år och står som ägare på abonnemanget vid skadetillfället gäller ordinarie självrisk.
                                    Om föraren är under 24 år och inte står som ägare gäller så kallad ungdomssjälrisk, 1000kr.
                                    Om mer än en självrisk gäller läggs beloppen ihop. Föraren som privatperson krävs aldrig på mer än 1/10 prisbasbelopp.
                                </p>
                            </div>
                            <div className={this.state.isToggled[15] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 15)}>
                                <p className="groupTitle">Vad täcker privatleasingskydd?<span className={this.state.isToggled[15] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    Försäkringen täcker finansieringsdelen av din abonnemangskostnad i upp till 12 månader om du skulle drabbas av ofrivillig arbetslöshet, hel arbetsoförmåga p.g.a.
                                    sjukdom eller olycksfall, sjukhusvistelse och dödsfall. Denna försäkring ingår de första tre månaderna därefter måste den tecknas för förlängning.
                                </p>
                            </div>
                            <div className={this.state.isToggled[16] ? 'infoGroup toggled' : 'infoGroup'} onClick={(e) => this.toggle(e, 16)}>
                                <p className="groupTitle">Hur fungerar återlämningen?<span className={this.state.isToggled[16] ? 'fbi-icon-angle-up' : 'fbi-icon-angle-down'}></span></p>
                                <p className="groupText">
                                    När abonnemangstiden löpt ut (oftast efter 36 månader) skall du lämna tillbaka bilen till din Volvohandlare. Bilen skall då vara i ett skick som motsvarar bilens ålder med normalt slitage. Därför är det viktigt att du sköter om din bil och är aktsam om den. Skador utöver normalt slitage får du betala för. Din Volvohandlare kommer att göra en utförlig besiktning av bilen för att avgöra skicket på bilen. Har du kört fler mil än avtalat tillkommer en kostnad för det (6 kr/övermil). Serviceboken ska vara korrekt ifylld och ligga med i bilen vid återlämning tillsammans med samtliga nycklar och eventuella avtagna delar/ tillbehör till bilen.
                                    <br /> <br />
                                    <a className="link" href='https://static.fbinhouse.se/2020-1/Normalt-slitage-CBV.84749454.pdf'>Mer information hittar du här (PDF)</a>
                                </p>
                            </div>
                        </div>

                        <div className="contacts">
                            <h2>Låt oss hjälpa dig</h2>
                            <br/>
                            <p><strong>Volvo Cars</strong></p>
                            <p>
                                Generella frågor kring Care by Volvo: <a href="tel:020906040">020-90 60 40</a> eller <a href="mailto:sweden@volvocars.com">sweden@volvocars.com</a>
                            </p>
                            <p>
                                Frågor om din beställning: <a href="tel:0313808200">031-380 82 00</a>
                            </p>
                            <p>
                                Volvo Assistans: <a href="tel:020555566">020-55 55 66</a> i Sverige och <a href="tel:+4631518335">+46 31-518 335</a> om du är utomlands.
                            </p>

                            <p>Volvo Studio Stockholm:
                                <a href="https://www.volvocars.com/se/om-volvo/foretaget/upplev-volvo-cars/studio-stockholm"> volvocars.com/studiostockholm</a>
                            </p>


                            <br/>
                            <p><strong>Volvia</strong></p>
                            <p>
                                Försäkringsärenden: <a href="tel:0313456700">031–345 67 00</a> eller <a href="mailto:info@volvia.se">info@volvia.se</a>
                            </p>
                            <p>
                                Skadeärenden: <a href="tel:0313456900">031-345 69 00</a> eller <a href="mailto:info@volvia.se">info@volvia.se</a>
                            </p>

                            <br/>
                            <h2 style={{paddingBottom: '10px', paddingTop: '20px'}}>Allmänna villkor</h2>
                            <div className="links">
                                <a style={{display: 'block', paddingBottom: '5px'}} href="https://assets.volvocars.com/se/~/media/Sweden/Documents/Service/allmanna-villkor-Care-by-Volvo-Privat-20191009.pdf">Care by Volvo</a>
                                <a href="https://www.volvia.se/globalassets/dokument/volvia-privat-villkor.pdf">Volvia försäkring</a>
                            </div>
                        </div>

                    </div>

                    <Hero></Hero>

                </div>

            </>
        );
    }
}

export default FrequentQuestions;
