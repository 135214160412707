
const FormatNum = (num, separator, fraction) => {
    var price = num.replace(/\s+/g, '');
    price = parseInt(price);
    var str = price.toLocaleString();
    str = str.replace(/\./, fraction);
    str = str.replace(/,/g, separator);
    return str;
}

export default FormatNum;
