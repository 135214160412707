import React, {Component} from 'react';
import posed from 'react-pose';
import axios from 'axios';
import {inject, observer} from 'mobx-react';
import Api from '../../../../Api';
import PriceFormat from '../../../../utils/PriceFormat';
import IncludedPopup from '../../IncludedPopup';
import Slider from './Slider';
import './styles.scss';

const body = document.getElementsByTagName("body")[0];
const Modal = posed.div({
    closed: {
        height: 'auto',
        delay: 400,
        transition: {duration: 300}
    },
    open: { height: 0 }
});


@inject('CbvStore')
@observer
class CarSummary extends Component {
    constructor(props) {
        super(props);
        this.lightBoxToggle = this.lightBoxToggle.bind(this);

        this.state = {
            showPopup: false,
            formError: false,
            showIncludedInfo: false
        };
    }
    componentDidMount() {
        Api.makeGet(`/specification/${this.props.CbvStore.carCis}`)
          .then(result => {
              var itemsArr = result.vspec_get_specification.items;

              this.props.CbvStore.cisTotalPrice = result.vspec_get_specification.total_price;

              itemsArr.map((val, index) => {
                  if(val.item_type === "COLOR") {
                      this.props.CbvStore.cisColor = val.cis_id + '=' + val.properties.short_text;
                  }
                  if(val.item_type === "UPHOLSTERY") {
                      this.props.CbvStore.cisUpholstery = val.cis_id + '=' + val.properties.short_text;
                  }
                  if(val.item_type === "GEARBOX") {
                      this.props.CbvStore.cisGearbox = val.properties.short_text;
                  }
                  if(val.item_type === "ENGINE") {
                      this.props.CbvStore.cisEngine = val.cis_id + '=' + val.properties.short_text;
                  }
                  if(val.item_type === "SALES_VERSION") {
                      this.props.CbvStore.cisSalesversion = val.properties.short_text;
                  }
                  if(val.item_type === "MODEL_YEAR") {
                      this.props.CbvStore.cisModelyear = val.properties.short_text;
                  }
                  if(val.item_type === "MODEL") {
                      this.props.CbvStore.cisModel = val.properties.short_text;
                  }
                  if(val.item_type === "OPTION") {
                      this.props.CbvStore.cisOptions = this.props.CbvStore.cisOptions + val.cis_id + '=' + val.properties.short_text + ';';
                  }
              })
              this.setState({formError: false})
          }).catch((error => {
              console.log(error);
              this.setState({formError: true})
          }));
    }

    getCurrentDate() {
        var d = new Date();
        var month = ["januari","februari","mars","april","maj","juni","juli","augusti","september","oktober","november","december"];

        return d.getDate() + ' ' + month[d.getMonth()] + ' ' + d.getFullYear()
    }

    validateEmail = (email) => {
       var re = /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       return re.test(email);
   }

    validateForm() {
        this.props.CbvStore.formError = false;
        let validateEmail = false;
        let validatedForm = false;
        let tillvalFormat = '';

        // Formating selected tillval to string for RB
        if(this.props.CbvStore.tillval.length > 0) {
          this.props.CbvStore.tillval.forEach((val, index) => {
            tillvalFormat = tillvalFormat + val.name + ', ';
          })
          tillvalFormat = tillvalFormat.replace(/,\s*$/, "");
        }

        let data = {
            customer_name: this.props.CbvStore.formName.substr(0, this.props.CbvStore.formName.indexOf(' ')),
            customer_surname: this.props.CbvStore.formName.substr(this.props.CbvStore.formName.indexOf(' ')+1),
            customer_email: this.props.CbvStore.formEmail,
            customer_phone: this.props.CbvStore.formPhone,
            customer_city: this.props.CbvStore.formPosition,
            customer_zipcode: this.props.CbvStore.formZip,
            customer_address: this.props.CbvStore.formAdress,
            ret_name: this.props.CbvStore.selectedDealer
        }


        // -- validate inputs -- //

        if(this.validateEmail(this.props.CbvStore.formEmail)) {
            validateEmail = true;
        } else {
            validateEmail = false;
        }

        if(this.props.CbvStore.formName.length > 0 &&
            this.props.CbvStore.formName.indexOf(' ') >= 0 &&
            this.props.CbvStore.formPhone.length > 0 &&
            this.props.CbvStore.formPosition.length > 0 &&
            this.props.CbvStore.formZip.length > 0 &&
            this.props.CbvStore.formAdress.length > 0 &&
            this.props.CbvStore.selectedDealer.length > 0) {

            validatedForm = true;
        } else {
            validatedForm = false;
        }

        // -- ---------------------- -- //

        if(validatedForm && validateEmail) {
            this.props.CbvStore.formError = false;
        } else {
            this.props.CbvStore.formError = true;
        }

        if(this.props.CbvStore.formError === false) {
            data.target_address = this.props.CbvStore.formEmail;
            data.car_specification = this.props.CbvStore.carCis;
            data.accessories = '-';
            data.customer_skype = '-';
            data.cont_time = '-';
            data.cont_media = '-';
            data.conf_url = 'http://byggdinvolvo.volvocars.se?cis=' + this.props.CbvStore.carCis;
            data.ticket = '-';
            data.origin = window.location.href;
            data.pdf_url = '-';
            data.ret_group = '-';
            data.pdf_attest_id = '-';
            data.duration = this.props.CbvStore.carData.price[0].months.toString();
            data.annual_milage = '1500';
            data.monthly_cost = this.props.CbvStore.getCarPrice;
            data.scoring_url = '-';
            data.ret_group = '-';
            data.Addons = tillvalFormat;
            data.ret_no = this.props.CbvStore.selectedDealerDistrict;
            data.ret_city = this.props.CbvStore.selectedDealerCity;
            data.customer_message = 'Säljare: (' + this.props.CbvStore.sellerID + ') ' + this.props.CbvStore.sellerEmail + '\n\n' + this.props.CbvStore.sellerHash;

            // car data
            data.my = this.props.CbvStore.cisModelyear;
            data.model = this.props.CbvStore.cisModel;
            data.sales_version = this.props.CbvStore.cisSalesversion;
            data.engine = this.props.CbvStore.cisEngine;
            data.transmission = this.props.CbvStore.cisGearbox;
            data.color = this.props.CbvStore.cisColor;
            data.upholstery = this.props.CbvStore.cisUpholstery;
            data.options = this.props.CbvStore.cisOptions;
            data.accessories = '-';
            data.exterior_image_url = this.props.CbvStore.carData.images[0];
            data.total_price = this.props.CbvStore.cisTotalPrice;

            this.formSubmit(data);
        }
    }


    formSubmit(data) {
        // formating form data
        let form_data = new FormData();
        for ( var key in data ) {
            form_data.append(key, data[key]);
        }

        let headers = {
            'Content-Type': 'multipart/form-data'
        }

        axios.post('https://www.clickredirect.net/_net4/volvo/Bygg_Din_Volvo/CareByVolvoInhouse/Send_Mail_Retailer.aspx', form_data, {headers: headers})
          .then(res => {
              // Direct to bekräftelse page
              this.props.CbvStore.navigate(`/bekraftelse/${this.props.CbvStore.carData.carModel.toLowerCase()}/${this.props.CbvStore.carData._id}`);

              this.props.CbvStore.formName = '';
              this.props.CbvStore.formEmail = '';
              this.props.CbvStore.formPhone = '';
              this.props.CbvStore.formPosition = '';
              this.props.CbvStore.formZip = '';
              this.props.CbvStore.formAdress= '';

          }).catch((error => {
              console.log(error);
          }));

        // Sending Mail to customer. (Commented this out, since RB is already sending their own email to the customer)
        /*

        let tillvalArr = [];
        if(this.props.CbvStore.tillval.length > 0) {
            this.props.CbvStore.tillval.map((val, index) => {
                tillvalArr.push({'name': val.name, 'price': val.price})
            })
        }
        let confirmationData = {
            'to': {
                'name': this.props.CbvStore.formName,
                'address': this.props.CbvStore.formEmail
            },
            'date': this.getCurrentDate(),
            'name': this.props.CbvStore.carData.name,
            'title': this.props.CbvStore.carData.title,
            'image': this.props.CbvStore.carImages[0] ? this.props.CbvStore.carImages[0] : this.props.CbvStore.carData.images[0],
            'color': {
                'name': this.props.CbvStore.selectedColor.name,
                'icon': this.props.CbvStore.selectedColor.icon,
                'price': this.props.CbvStore.selectedColor.price
            },
            'additional': tillvalArr.length > 0 ? tillvalArr : [],
            'total': 5995,
            'months': 36,
            'mileage': 1500
        };

        Api.makePost('confirmation',  confirmationData )
        .then(res => {
            this.props.CbvStore.navigate(`/bekraftelse/${this.props.CbvStore.carData.carModel.toLowerCase()}/${this.props.CbvStore.carData._id}`);

        }).catch((error => {
            console.log(error);
        }));

        */

    }

    handleRemoveTillval = (name, price) => (e) => {
        this.props.CbvStore.removeFromTillval(name, price)
    }

    toggleIncludedInfo = () => {
        this.setState({showIncludedInfo: !this.state.showIncludedInfo})
        this.setState({showPopup: !this.state.showPopup})

        body.classList.add('disableScroll')
    }

    lightBoxToggle() {
        this.setState({showPopup: false})
        this.setState({showIncludedInfo: false})
        let tillvalToggled = [];
        this.setState({tillvalToggled});

        body.classList.remove('disableScroll')
    }

    closeTillvalInfo = () => {
        this.setState({showPopup: false})
        this.setState({showIncludedInfo: false})
        let tillvalToggled = [];
        this.setState({tillvalToggled});

        body.classList.remove('disableScroll')
    }

    render() {
        const {CbvStore} = this.props;

        return (
            <div className="carSummaryWrapper">
                <div className="optionsWrapper">
                    <div className="textWrapper">
                        <h2>{CbvStore.carData.name}</h2>
                        <p className="subtitle">{CbvStore.carData.title}</p>
                    </div>

                    <Slider></Slider>

                    <Modal initialPose="open" pose="closed" className="poseWrapper">
                        <div className="colorsWrapper">
                            <p className="colorsTitle optionsTitle">Färg</p>

                            <div className="colorContainer">
                                <div className="selectedColorWrapper">
                                    <img src={CbvStore.selectedColor.icon} className="color" alt=""></img>
                                    <p className="selectedColor">{CbvStore.selectedColor.name}</p>
                                </div>
                                <p className="colorPrice">+{CbvStore.selectedColor.price} kr/mån</p>
                            </div>

                        </div>

                        <div className="tillValWrapper">
                            <p className="optionsTitle">Tillval</p>

                            {CbvStore.tillval.map((val, index) => {
                                return (
                                    <div className="container" key={index}>
                                        <div className="optionWrapper">
                                            <span className="fbi-icon-cancel" onClick={this.handleRemoveTillval(val.name, val.price)}></span>
                                            <p className="option">{val.name}</p>
                                        </div>
                                        <p className="optionPrice">+{val.price} kr/mån</p>
                                    </div>
                                )
                            })}

                            {CbvStore.tillval.length === 0 ? <p>Du har inga tillval</p> : null}

                        </div>
                    </Modal>

                    <div className="priceWrapper">
                        <div className="cbvPrice">
                            <p className="optionsTitle">Din månadskostnad</p>
                            <div className="totalPriceWrapper">
                                <h2 onClick={this.toggleIncludedInfo} className="totalPrice">{CbvStore.getCarPrice} kr/mån  <span className="fbi-icon-info cbvIncludedInfo"></span></h2>
                                <p className="totalSub">{CbvStore.carData.price[0].months} månader. {PriceFormat(CbvStore.carData.price[0].mileage.toString(), ' ', 'x')} mil/år</p>
                            </div>
                        </div>

                        <div data-am-button>
                            <div className="blue-button-nofade" onClick={this.validateForm.bind(this)}>
                                <p>Boka din bil</p>
                            </div>
                        </div>
                    </div>

                    { CbvStore.formError ?
                        <p className="formErrorText"
                            style={{color: '#ee5661', textAlign: 'center', paddingTop: '10px', fontFamily: 'Volvo Novum Regular'}}>
                            Någonting gick fel. Kontrollera formuläret och försök att skicka din förfrågan igen. Lyckas du ändå inte, kontakta oss på <a href="mailto:sweden@volvocars.com">sweden@volvocars.com</a>
                        </p>
                    : null }

                    <div className="speech-bubble">
                        Snart är du klar! Boka din bil så kontaktar vi dig och går igenom din beställning tillsammans.
                        Du betalar ingenting innan du har fått din bil.
                    </div>

                </div>

                <IncludedPopup
                    showIncludedInfo={this.state.showIncludedInfo}
                    closeIncludedInfo={this.closeTillvalInfo}>
                </IncludedPopup>

                {this.state.showPopup ?
                    <div className="lightboxOverlay" onClick={this.lightBoxToggle}></div>
                    : null
                }

            </div>
        );
    }
}

export default CarSummary;
