import 'babel-polyfill';

var realFunc = window.Object['getOwnPropertySymbols'];
var polyfillFunc = function(value) {
    if (typeof value !== "object") {
        return [];
    } else {
        return realFunc.apply(this, [value]);
    }
}
window.Object['getOwnPropertySymbols'] = polyfillFunc;
