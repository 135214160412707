import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import './styles.scss';

let prevPageLabel;
let prevPageLink;

@inject('CbvStore')
@observer
class SecondaryNavigation extends Component {
    render() {
        const {page, Link, type, carModel} = this.props.CbvStore;

        if(window.previousLocation) {
            if(window.previousLocation.pathname === '/') {
                prevPageLabel = 'Care by Volvo';
                prevPageLink = '/';
            }
            if(window.previousLocation.pathname === '/valj-modell') {
                prevPageLabel = 'Välj bil';
                prevPageLink = '/valj-modell';
            }
            if(window.previousLocation.pathname.indexOf('/modell/') > -1) {
                prevPageLabel = 'Anpassa bil';
                prevPageLink = window.previousLocation.pathname;
            }
            if(window.previousLocation.pathname.indexOf('/slutfor/') > -1) {
                prevPageLabel = 'Starta abonnemang';
                prevPageLink = window.previousLocation.pathname;
            }
        } else {
            prevPageLabel = 'Välj bil';
            prevPageLink = '/valj-modell';
        }

        let backTo;
        let backToLabel;
        if(page === 'valjModell' || page === 'pageNotFound') {
            backTo = "/";
            backToLabel = 'Care by Volvo';
        }
        if(page === 'modell') {
            backTo = "/valj-modell"
            backToLabel = 'Välj bil';
        }
        if(page === 'slutfor') {
            backTo = "/modell/" + carModel + '/' + type
            backToLabel = "Anpassa bil";
        }
        if(page === 'bekraftelse') {
            backTo = "/";
            backToLabel = "Care by Volvo";
        }
        if(page === 'vanligaFragor') {
            backTo = prevPageLink;
            backToLabel = prevPageLabel;
        }

        return (
            <>
            {page !== 'start' ?
                <div className="cbv-secondary-navigation">
                    <Link to={backTo ? backTo : ''} className="arrowStepBackWrapper">
                        <div className="arrowStepBack">
                            <span className="fbi-icon-angle-left">
                                <p>Tillbaka till <strong> {backToLabel}</strong></p>
                            </span>
                        </div>
                    </Link>
                </div>
            : null}
            </>
        );
    }
}

export default SecondaryNavigation;
