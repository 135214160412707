import React, {Component} from 'react';
import posed, { PoseGroup } from 'react-pose';
import {Provider, observer} from 'mobx-react';

import CbvStore from './stores/CbvStore';
import App from './components/App';
import ValjModell from './components/CbvCarPage/valjModell';
import Slutfor from './components/CbvCarPage/slutfor';
import Complete from './components/CbvCarPage/complete';
import Modell from './components/CbvCarPage/modell';
// import Contact from './components/CbvCarPage/Contact';
// import StepIndicator from './components/CbvCarPage/StepIndicator';
import SecondaryNavigation from './components/CbvCarPage/SecondaryNavigation';
import FrequentQuestions from './components/FrequentQuestions';
import PageNotFound from './components/PageNotFound';

import ScrollToTop from "./ScrollToTop";

const RouteContainer = posed.div({
    enter: { opacity: 1, delay: 80, transition: {duration: 400} },
    exit: { opacity: 0, transition: {duration: 400} }
});

@observer
class AppWrapper extends Component {

    constructor(props) {
        super(props)

        this.cbvStore = new CbvStore();

        this.cbvStore.page = props.page;
        this.cbvStore.id = props.id;
        this.cbvStore.type = props.type;
        this.cbvStore.Link = props.Link;
        this.cbvStore.carModel = props.carModel;
        this.cbvStore.navigate = props.navigate;
    }

    componentDidUpdate() {
        if (window.hasRoutedOnce) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Pageview',
                'url': window.location.href
            });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.page !== this.props.page) {
            this.cbvStore.page = nextProps.page;
        }

        if (nextProps.carModel !== this.props.carModel) {
            this.cbvStore.carModel = nextProps.carModel;
        }

        if (nextProps.id !== this.props.id) {
            this.cbvStore.id = nextProps.id;
        }

        if (nextProps.type !== this.props.type) {
            this.cbvStore.type = nextProps.type;
        }

        if (nextProps.Link !== this.props.Link) {
            this.cbvStore.Link = nextProps.Link;
        }
        if (nextProps.navigate !== this.props.navigate) {
            this.cbvStore.navigate = nextProps.navigate;
        }

        window.previousLocation = this.props.location;
    }

    render() {

        window.onpopstate = function() {
            document.getElementsByTagName("body")[0].classList.remove('disableScroll');
        }

        return (
            <Provider CbvStore={this.cbvStore}>
                <div className="outerAppWrapper">

                    <SecondaryNavigation />

                    {/*<StepIndicator
                        page={this.props.page}
                    />*/}

                    <PoseGroup>
                        <RouteContainer key={window.location}>
                            <ScrollToTop>
                                <div className="AppWrapper">
                                    { this.cbvStore.page === 'start' ?
                                    <>
                                        <App></App>
                                        {/*<Contact className="contactStart" showOnMobile={this.cbvStore.page === 'modell' ? true : false}></Contact>*/}
                                    </>
                                        : null

                                    }

                                    { this.cbvStore.page !== 'start' ?

                                        <div className="cbvCarPage-section">

                                            { this.cbvStore.page === 'valjModell' ?
                                                <ValjModell></ValjModell>
                                                : null
                                            }

                                            { this.cbvStore.page === 'modell' ?
                                                <Modell></Modell>
                                                : null
                                            }

                                            { this.cbvStore.page === 'slutfor' ?
                                                <Slutfor></Slutfor>
                                                : null
                                            }

                                            { this.cbvStore.page === 'bekraftelse' ?
                                                <Complete></Complete>
                                                : null
                                            }

                                            { this.cbvStore.page === 'vanligaFragor' ?
                                                <FrequentQuestions></FrequentQuestions>
                                                : null
                                            }

                                            { this.cbvStore.page === 'pageNotFound' ?
                                                <PageNotFound></PageNotFound>
                                                : null
                                            }

                                            {/*<Contact showOnMobile={this.cbvStore.page === 'modell' ? true : false}></Contact>*/}

                                        </div>

                                    : null }
                                </div>
                            </ScrollToTop>
                        </RouteContainer>
                    </PoseGroup>
                </div>
            </Provider>
        );
    }
}

export default AppWrapper;
