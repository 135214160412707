import axios from 'axios';
import jwt from 'jsonwebtoken';

class Api {
  constructor(backendEndPoint, secret) {
    const token = jwt.sign(
      {
        iss: 'vcs-care-by-volvo.frontend',
        //sub: '3316e7de-eb70-490a-a8ce-e6c4400093ad',
        exp: Math.floor(Date.now() / 1000) + (60 * 60),
        aud: [
          "http://vcs-care-by-volvo/"
        ],
      },
      Buffer.from(secret, 'base64'),
      {
        algorithm: 'HS256',
      }
    );

    const options = {
      baseURL: backendEndPoint,
      headers: { Authorization: `Bearer ${token}` },
    };

    this.axios = axios.create(options);
  }

  makeGet(path) {
      return this.makeRequest(this.axios.get(path));
  }

  makePost(path, data = {}) {
      return this.makeRequest(this.axios.post(path, data));
  }

  makePatch(path, data = {}) {
      return this.makeRequest(this.axios.patch(path, data));
  }

  makeDelete(path) {
      return this.makeRequest(this.axios.delete(path));
  }

  makeRequest(request) {
      return request.then(res => {
          if (res.status === 200) {
              return res.data;
          } else {
              console.error("Non 200");
              throw new Error("Expecting status 200 but received status: " + res.status);
          }
      }).catch(error => {
          if(error.response) {
              console.log(error.response.data.error);
              throw new Error(error.response.data.error);
          } else if(error.request) {
              console.log(error.request);
              throw new Error('Something went wrong when contacting the API');
          } else {
              console.log('Error', error.message);
              throw new Error('Unknown error');
          }
      });
  }
}

export default new Api(window.CBV_CONFIG.BACKEND_API_ENDPOINT, window.CBV_CONFIG.BACKEND_API_KEY);
