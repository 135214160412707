import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import Slider from "react-slick";
import posed from 'react-pose';

import './styles.scss';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
          className={className}
          onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
          className={className}
          onClick={onClick}
        />
    );
}

const Modal = posed.div({
    enter: { x: "0", opacity: 1, delay: 150, transition: {type: 'spring', stiffness: 210, damping: 22} },
    exit: { x: "300px", opacity: 0, delay: 0, transition: {type: 'spring', stiffness: 210, damping: 22} }
});

@inject('CbvStore')
@observer
class CarSlider extends Component {

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            //lazyLoad: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };


        const {CbvStore} = this.props;

        if(!CbvStore.carData) {
            return <div className="pageLoader"></div>
        }

        let carImages = CbvStore.carImages.length > 0 ? CbvStore.carImages : CbvStore.carData.images;


        return (
            <Modal className="step2CarPose sliderWrapper">
                <Slider {...settings}>
                    {carImages.map((image, index) =>
                        <div key={index}>
                            <img src={image} alt="" />
                        </div>
                    )}
                </Slider>
            </Modal>
        );
    }
}

export default CarSlider;
