import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import './styles.scss';
import PriceFormat from '../../../../../utils/PriceFormat';

import CarSlider from './Slider';

@inject('CbvStore')
@observer
class CbvCar extends Component {

    render() {
        const {Link} = this.props.CbvStore;
        var popularStyle = {
            paddingTop: '50px',
        };

        return (
            <div className="cbvCarWrapper" style={this.props.tags.includes("Popular") ? popularStyle : null}>

                { this.props.tags.includes("Popular") ?
                    <div className="popular">
                        <p>POPULÄR</p>
                    </div>
                : null }

                <div className="cbvCarTitleWrapper">
                    <h2 className="carName">{this.props.name}</h2>
                    <p className="carTitle">{this.props.title}</p>
                </div>

                <CarSlider
                    images={this.props.images}
                >
                </CarSlider>

                <div className="cbvCarPriceWrapper">
                    <div className="cbvPrice">
                        <h2>{PriceFormat(this.props.price[0].basePrice.toString(), ' ', 'x')} kr/mån</h2>
                        <p>{this.props.price[0].months} månader. {PriceFormat(this.props.price[0].mileage.toString(), ' ', 'x')} mil/år</p>
                    </div>
                    <div data-am-button>
                        <Link to={`/modell/${this.props.carModel}/${this.props.id}`} title="" className="blue-button-nofade">
                            Anpassa bil
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default CbvCar;
