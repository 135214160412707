import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import axios from 'axios';
import './styles.scss';

@inject('CbvStore')
@observer
class Retailers extends Component {

    componentDidMount() {
        var inter = false; // make sure it renders once
        var timer;

        var getCompanyData = () => {
            clearInterval(timer);
            axios.get('https://dms.fbinhouse.se/api/company')
            .then(res => {
                res.data.forEach((val, index) => {
                    if(val.info.profile.occupation && val.info.profile.occupation.toLowerCase().includes("sälj")) {
                        this.props.CbvStore.dealerList.push(val)
                    }
                    if(val.children.length > 0) {
                        val.children.forEach((val, index) => {
                            if(val.info.profile.occupation && val.info.profile.occupation.toLowerCase().includes("sälj")) {
                                this.props.CbvStore.dealerList.push(val)
                            }
                        })
                    }
                })
            }).catch(function (error) {
                console.log(error);
            });

            inter = true;
        }

        if(inter === false && !this.props.CbvStore.dealerList.length > 0) {
            timer = setInterval(getCompanyData, 1000);
        }

        if(this.props.CbvStore.selectedDealer.length > 0) {
            document.getElementById('dealerSelect').value = this.props.CbvStore.selectedDealer;
        }
    }

    handleSelect(event) {
        let selectedDealer = event.target.value;
        let selectedDealerCity = event.target[event.target.selectedIndex].getAttribute('data-city')
        let selectedDealerDistrict = event.target[event.target.selectedIndex].getAttribute('data-district')

        this.props.CbvStore.selectedDealer = selectedDealer;
        this.props.CbvStore.selectedDealerCity = selectedDealerCity;
        this.props.CbvStore.selectedDealerDistrict = selectedDealerDistrict;
    }

    sortDealerList( a, b ) {
        if ( a.info.profile.visit_post_town < b.info.profile.visit_post_town ) {
            return -1;
        }
        if ( a.info.profile.visit_post_town > b.info.profile.visit_post_town ) {
            return 1;
        }
        return 0;
    }

    render() {
        let sortedDealerList = this.props.CbvStore.dealerList.sort( this.sortDealerList );

        return (
            <div className="retailersWrapper">
                <span className="fbi-icon-angle_down" style={{"pointerEvents":"none"}}></span>
                <label>Välj var du vill hämta ut och serva din bil</label>
                <select defaultValue="" onChange={(e) => this.handleSelect(e)} id="dealerSelect" className={this.props.CbvStore.selectedDealer.length <= 0 && this.props.CbvStore.formError ? 'inputError dealerSelect' : 'dealerSelect'}>
                    <option value="" disabled>Välj din Volvohandlare</option>
                    {sortedDealerList.map((val, index) => {
                        return val.name.indexOf('Volvo Bil') === -1 ? <option data-city={val.info.profile.mail_post_town} visit_post_town={val.info.profile.visit_post_town} data-district={val.branchCode} key={index} value={val.name}>{val.info.profile.visit_post_town + ' - ' + val.name}</option> : null
                    })}
                </select>
                {this.props.CbvStore.selectedDealer.length <= 0 && this.props.CbvStore.formError ? <p>Välj din Volvohandlare</p> : null}
            </div>
        );
    }
}

export default Retailers;
