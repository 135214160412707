import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import './styles.scss';

@inject('CbvStore')
@observer
class PageNotFound extends Component {

    goBack = () => {
        this.props.CbvStore.navigate('/')
    }

    render() {

        return (
            <div className="noMatchWrapper">
                <div className="noMatchContent">
                    <h1>Sidan kan inte hittas</h1>
                    <p>
                        Sidan du söker verkar inte finnas här.
                        Kolla så att du har stavat rätt och försök igen.
                        Eller klicka på länken nedan för att komma till startsidan.
                    </p>

                    {
                        <p className="goBack" onClick={this.goBack}>Tillbaka till Care by Volvo</p>
                    }
                </div>
            </div>
        )
    }
}

export default PageNotFound;
