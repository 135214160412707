const JWT = require('jsonwebtoken');
const arc4 = require('arc4/min/lib/normal/arc4');

class ObfuscatedRequest {
    constructor(jwtIssuer, jwtAudience, jwtSharedKey) {
        this.jwtIssuer = jwtIssuer;
        this.jwtAudience = jwtAudience;
        this.jwtSharedKey = jwtSharedKey;
    }

    create(requestObject) {
        let jwtString = JWT.sign(requestObject, this.jwtSharedKey, {
            algorithm: 'HS256',
            expiresIn: '60s',
            audience: this.jwtAudience,
            issuer: this.jwtIssuer,
        });

        // console.log('JWT created', jwtString);

        let hostname = document.location.hostname;

        // console.log('Current hostname', hostname);
        let cipher = arc4(hostname);
        return cipher.encodeString(jwtString, 'utf8', 'base64');
    }

    decode(data) {
        let hostname = document.location.hostname;
        let cipher = arc4(hostname);
        return JSON.parse(cipher.decodeString(data, 'base64', 'utf8'));
    }

}

export default new ObfuscatedRequest('vcs-care-by-volvo.frontend', 'vcs-care-by-volvo.backend', 'JWT-CBV-sharedkey');
