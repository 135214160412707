import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, Link } from 'react-router-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, StyleProvider } from 'vcc-ui';
import volvo from "vcc-ui/lib/themes/volvo";


import {
    SiteNavigation,
    SiteFooter
} from './components/SiteNavigation/SiteNavigation';
import 'react-app-polyfill/ie11';
import AppWrapper from './AppWrapper';


window.hasRoutedOnce = false;

if(window.hasRoutedOnce === false) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'SPA': true
    });
    window.hasRoutedOnce = true;
}

const pathPrefix = `${window.WEBPACK_PUBLIC}static/`;

const Routing = () => (
    <Route render={(route) => {
        return <div>
            <Switch>
                <Route exact path={'/'} render={(props) => <AppWrapper {...props} navigate={props.history.push} Link={Link} page='start' />} />
                
                {/*<Route path={'/bekraftelse/:carModel/:id'} render={(props) => <AppWrapper {...props} navigate={props.history.push} Link={Link} page='bekraftelse' />} />
                <Route path={'/valj-modell'} render={(props) => <AppWrapper {...props} navigate={props.history.push} Link={Link} page='valjModell' />} />
                <Route path={'/modell/:carModel/:id'} render={(props) => <AppWrapper {...props} navigate={props.history.push} Link={Link} page='modell' carModel={props.match.params.carModel} id={props.match.params.id}/>} />
                <Route path={'/slutfor/:carModel/:type'} render={(props) => <AppWrapper {...props} navigate={props.history.push} Link={Link} page='slutfor' carModel={props.match.params.carModel} type={props.match.params.type} />} />
                <Route exact path={'/vanliga-fragor'} render={(props) => <AppWrapper {...props} navigate={props.history.push} Link={Link} page='vanligaFragor' />} />*/}

                <Route render={(props) => <AppWrapper {...props} navigate={props.history.push} Link={Link} page='start' />} />
                
                {/*<Route render={(props) => <AppWrapper {...props} navigate={props.history.push} Link={Link} page='pageNotFound' />} />*/}

            </Switch>
        </div>
    }} />
);

ReactDOM.render(
    <StyleProvider>
        <ThemeProvider
            theme={{
                ...volvo,
                fontsPath: pathPrefix + 'vcc-ui/fonts/',
                logoImagesPath: pathPrefix + 'vcc-ui/images/'
            }}
        >
            <>
                {process.env.NODE_ENV === 'development' && <SiteNavigation />}
                <BrowserRouter basename={window.WEBPACK_PUBLIC}>
                    <Routing />
                </BrowserRouter>
                {process.env.NODE_ENV === 'development' && <SiteFooter />}
            </>
        </ThemeProvider>
    </StyleProvider>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
