import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import Api from '../../../Api';
import ContactMe from '../ContactMe';

import CbvCars from './CbvCars';
//import Banner from './Banner';
import './styles.scss';

@inject('CbvStore')
@observer
class ValjModell extends Component {

    componentDidMount() {
        // Get data if direct link to valjModell (else getting data from start)
        if(!this.props.CbvStore.cars) {
            Api.makeGet('/car')
            .then(data => {
                this.props.CbvStore.cars = data;
            })
            .catch(err => { console.log(err) })
        }

        // Resetting some option data to default
        // Inside timer to trigger after animation is complete
        var inter = false;
        setInterval(() => {
            if(inter === false) {
                this.props.CbvStore.carImages = [];
                this.props.CbvStore.dynamicTillval = {};
                this.props.CbvStore.tillval = [];
                this.props.CbvStore.colorSelected = false;
                this.props.CbvStore.carPrice = '0';
                inter = true;
            }
        }, 400);


        // Tracking - Pixels
        window.Optanon.InsertHtml('<img src="https://ad.doubleclick.net/ddm/activity/src=8562653;type=volvo001;cat=se-lo002;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/> <img src="https://secure.adnxs.com/px?id=1034505&seg=14681547&t=2" width="1" height="1"/> <img src="//se-gmtdmp.mookie1.com/t/v2/activity?tagid=V2_669677&src.rand=[timestamp]&" style="display:none;"/> <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1789914651042181&ev=PageView&noscript=1"/> <img src="https://secure.adnxs.com/px?id=1055685&t=2" width="1" height="1" /> <img src="https://secure.adnxs.com/px?id=1078604&t=2" width="1" height="1" />', 'fbiPixels', null, null, 4);
    }

    render() {
        const {cars} = this.props.CbvStore;

        if(!cars) {
            return <div className="pageLoader"></div>
        }

        return (
            <div className="stepOuterWrapper">

                {/*<div className="mobile-back">
                    <h2 className="backTitle">Välj modell</h2>
                    <Link to={"/#"} title="">
                        <span className="iconWrapper">
                            <span className="arrowBack fbi-icon-angle-left"></span>
                        </span>
                    </Link>
                </div>*/}

                <div className="stepInnerWrapper">

                    <div className="header">
                        <h3>CARE BY VOLVO</h3>
                        <h1>Aktuella erbjudanden</h1>
                        {/*<p style={{maxWidth: '420px', padding: '0 10px'}}>
                            Denna vecka laddar vi upp med helt nya kampanjbilar. Kan du inte vänta? Då hittar du  alla våra modeller i Bygg din Volvo.
                        </p>*/}
                    </div>

                    {/*<div data-am-button style={{textAlign: 'center'}}>
                        <a className="blue-button-nofade" href="https://byggdinvolvo.volvocars.se/se/bilar/bygg-din-volvo/#/welcome?vdsparam=cHJpY2VwYXJhbT1wcmljZU1vZGUlM0RWT0xWT19DQVJFJTI2ZHJpdmluZ19kaXN0YW5jZSUzRDE1MDAlMjZwYXltZW50X3BlcmlvZHMlM0QyNA%253D%253D">Starta Bygg din Volvo</a>
                    </div>*/}

                    <CbvCars
                        cars={cars}
                    >
                    </CbvCars>

                    <ContactMe></ContactMe>

                    <p style={{textAlign: 'center'}}>Du hittar alla modeller i
                        <a href="http://byggdinvolvo.volvocars.se/se/bilar/bygg-din-volvo/"> Bygg din Volvo.</a>
                    </p>
                </div>

            </div>
        );
    }
}

export default ValjModell;
