import React, {Component} from 'react';
import Slider from "react-slick";

import './styles.scss';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
          className={className}
          onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
          className={className}
          onClick={onClick}
        />
    );
}

class CarSlider extends Component {

    render() {
        
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            //lazyLoad: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        return (
            <div className="sliderWrapper valjModell">
                <Slider {...settings}>
                    {this.props.images.map((image, index) =>
                        <div key={index}>
                            <img src={image} alt="" />
                        </div>
                    )}
                </Slider>
            </div>
        );
    }
}

export default CarSlider;
