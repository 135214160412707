import {observable, action, computed} from 'mobx';
import PriceFormat from '../utils/PriceFormat';

class CbvStore {
    @observable route = 'start';

    @observable id;
    @observable type;
    @observable Link;
    @observable carModel;
    @observable page = 'start';
    @observable navigate;
    @observable chatOnline = false;

    @observable navigatedLastStep = false;
    //
    @observable contactPopup = false;

    // Car data
    @observable cars = null;
    @observable carStartPrice = '';
    @observable carPrice = '';
    @observable lowestCarPrice = null;
    @observable carData = null;
    @observable carCis = '';
    @observable fetchedApiData = false;

    @observable selectedColor = {};
    @observable colorSelected = false;
    @observable carImages = [];

    @observable tillval = []; // Saving selected tillvals here
    @observable dynamicTillval = {};


    @action addToCarPrice(price) {
        this.carPrice = (+this.carPrice + +price).toString();
    }

    @action removeFromCarPrice(price) {
        this.carPrice = (+this.carPrice - +price).toString();
    }

    @action removeFromTillval(tillvalValue, price) {
        this.dynamicTillval[tillvalValue] = false;
        this.removeFromCarPrice(price)

        this.tillval.splice(this.tillval.findIndex(matchesEl), 1);
        function matchesEl(el) {
            return el.name === tillvalValue;
        }
    }

    @computed get getCarPrice() {
        return PriceFormat(this.carPrice, ' ', 'x');
    }

    // Slutfor form user data
    @observable formError = false;
    @observable formName = '';
    @observable formEmail = '';
    @observable formPhone = '';
    @observable formAdress = '';
    @observable formZip = '';
    @observable formPosition = '';
    @observable dealerList = [];
    @observable selectedDealer = '';
    @observable selectedDealerCity = '';
    @observable selectedDealerDistrict = '';

    // Seller data
    @observable sellerID = '';
    @observable sellerHash = '';
    @observable sellerEmail = '';

    // Form - car data from cis
    @observable cisOptions = '';
    @observable cisModel = '';
    @observable cisModelyear = '';
    @observable cisSalesversion = '';
    @observable cisEngine = '';
    @observable cisGearbox = '';
    @observable cisUpholstery = '';
    @observable cisColor = '';
    @observable cisTotalPrice = '';

    @action handleFormChange = (data) => {
        this.formObject = data;
    }

    /* ------------------------------ */

    @action changeRoute = (route) => {
        this.route = route;
    }

    /*constructor() {
        window._imbox.push(['getOnlineStatus', (data) => {
             if(data === '1') {
                 this.chatOnline = true;
             } else {
                 this.chatOnline = false;
             }
         }]);
    }*/

}

export default CbvStore;
