
import API from '../Api';
import ObfuscatedRequest from './obfuscatedRequest';

const lookupSSN = (ssn) => {
    return API.makePost('/spar/ssn', {
        q: ObfuscatedRequest.create({
            ssn: ssn,
        })
    }).then(data => {
        // Decrypt
        return ObfuscatedRequest.decode(data);
    });
}

export default { lookupSSN };
