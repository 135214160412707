import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import PriceFormat from '../../../utils/PriceFormat';
//import Slider from './Slider';
import './styles.scss';

var uniqid = require('uniqid'); // for analytics ecommerce

@inject('CbvStore')
@observer
class Complete extends Component {

    componentDidMount() {
        if(!this.props.CbvStore.fetchedApiData) {
            this.props.CbvStore.page = 'bekraftelse'
        }
        if(!this.props.CbvStore.carData) {
            this.props.CbvStore.navigate('/valj-modell');
        } else {

            // ** -------- Tracking Ecommerce --------- ** //

            // Format price for tracking
            //let priceInt = this.props.CbvStore.getCarPrice;
            //priceInt = priceInt.replace(/^\s+|\s+$/g, '');

            //priceInt = priceInt.replace(/ /g, '');
            //priceInt = parseInt(priceInt, 10);

            let startPriceInt = this.props.CbvStore.carStartPrice;
            startPriceInt = startPriceInt.replace(/ /g, '');
            //startPriceInt = parseInt(startPriceInt, 10);

            let ecommerceProducts = [];

            // The car
            ecommerceProducts.push({
                'sku': this.props.CbvStore.carCis ? this.props.CbvStore.carCis : null,
                'name': this.props.CbvStore.carData.name ? this.props.CbvStore.carData.name : null,
                'category': 'Car',
                'price': startPriceInt ? startPriceInt : null,
                'quantity': 1,
            })

            // Tillval
            if(this.props.CbvStore.tillval.length > 0) {
                this.props.CbvStore.tillval.map((val, index) => {
                    ecommerceProducts.push({
                        'sku': val.id,
                        'name': val.name,
                        'category': 'Package',
                        'price': parseInt(val.price),
                        'quantity': 1
                    })
                })
            }

            // Color
            ecommerceProducts.push({
                'sku': this.props.CbvStore.selectedColor.cis,
                'name': this.props.CbvStore.selectedColor.name,
                'category': 'Color',
                'price': parseInt(this.props.CbvStore.selectedColor.price),
                'quantity': 1
            })

            console.log(this.props.CbvStore.getCarPrice.replace(/\s/g, ""));

            let ecommerceObj = {
                'event': 'Purchase',
                'transactionId': uniqid(),
                'transactionAffiliation': 'Care by Volvo',
                'transactionTotal': this.props.CbvStore.getCarPrice.replace(/\s/g, ""),
                'transactionProducts': ecommerceProducts
            }



            if (typeof window !== 'undefined' && window.dataLayer) {
                window.dataLayer.push(ecommerceObj)
            }


            // ** --------------- Ecommerce Tracking End ---------------- ** //

            // Tracking - Pixels
            window.Optanon.InsertHtml('<img src="https://ad.doubleclick.net/ddm/activity/src=8562653;type=volvo001;cat=se-lo001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?" width="1" height="1" alt=""/> <img src="https://secure.adnxs.com/px?id=915036&seg=11905404&t=2" width="1" height="1" /> <img src="//se-gmtdmp.mookie1.com/t/v2/activity?tagid=V2_669670&src.rand=[timestamp]&" style="display:none;"/> <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1789914651042181&ev=PageView&noscript=1"/> <img src="https://secure.adnxs.com/px?id=1055683&t=2" width="1" height="1" />', 'fbiPixels', null, null, 4);

        }

    }

    getCurrentDate() {
        var d = new Date();
        var month = ["januari","februari","mars","april","maj","juni","juli","augusti","september","oktober","november","december"];

        return d.getDate() + ' ' + month[d.getMonth()] + ' ' + d.getFullYear()
    }

    render() {
        const {CbvStore} = this.props;

        if(!this.props.CbvStore.fetchedApiData || !this.props.CbvStore.carData) {
            return <div></div>
        }

        return (
            <div className="carSummaryWrapper orderCompleteWrapper">
                <div className="orderComplete">
                    <h1>Tack!</h1>
                    <p>
                        Du kommer att bli kontaktad inom kort.
                        Tillsammans går vi igenom din beställning och ser till att ditt abonnemang uppfyller dina behov.
                    </p>

                    <p>
                        <strong>Beställningsdatum</strong>: {this.getCurrentDate()}
                    </p>

                    <div className="optionsOrderWrapper orderWrapper">
                        <div className="innerWrapper">

                            <div className="textWrapper">

                                <h2>{CbvStore.carData.name}</h2>
                                <p className="subtitle">{CbvStore.carData.title}</p>
                            </div>

                            <img className="orderImg" src={CbvStore.carImages[0] ? CbvStore.carImages[0] : CbvStore.carData.images[0]} alt=""></img>

                            <div className="poseWrapper">
                                <div className="colorsWrapper">
                                    <p className="colorsTitle optionsTitle">Färg</p>

                                    <div className="colorContainer">
                                        <div className="selectedColorWrapper">
                                            <img src={CbvStore.selectedColor.icon} className="color" alt=""></img>
                                            <p className="selectedColor">{CbvStore.selectedColor.name}</p>
                                        </div>
                                        <p className="colorPrice">+{CbvStore.selectedColor.price} kr/mån</p>
                                    </div>

                                </div>

                                {CbvStore.tillval.length > 0 ?
                                    <div className="tillValWrapper">
                                        <p className="optionsTitle">Tillval</p>

                                        {CbvStore.tillval.map((val, index) => {
                                            return (
                                                <div className="container" key={index}>
                                                    <div className="optionWrapper">
                                                        <p className="option">{val.name}</p>
                                                    </div>
                                                    <p className="optionPrice">+{val.price} kr/mån</p>
                                                </div>
                                            )
                                        })}

                                    </div>
                                : null}

                            </div>

                            <div className="priceWrapper">
                                <div className="cbvPrice">
                                    <p className="optionsTitle">Din månadskostnad</p>
                                    <div className="totalPriceWrapper">
                                        <p className="totalPrice">{CbvStore.getCarPrice} kr/mån</p>
                                        <p className="totalSub">{CbvStore.carData.price[0].months} månader. {PriceFormat(CbvStore.carData.price[0].mileage.toString(), ' ', 'x')} mil/år</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <p>
                        Har du frågor är du välkommen att kontakta oss
                        via <a href="mailto:sweden@volvocars.com">sweden@volvocars.com</a>
                    </p>
                </div>
            </div>
        );
    }
}

export default Complete;
